var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2", attrs: { id: "newFormDialog" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "elevation-0", attrs: { dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("New Form")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("new-form-close")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "elevation-0" },
        [
          _c("validation-observer", { ref: "form" }, [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Title", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Title",
                                      "hide-details": "auto",
                                      "error-messages": errors,
                                      success: valid,
                                      color: "primary",
                                      name: "title",
                                      hint: "Title of form. This can be changed later.",
                                      "persistent-hint": "",
                                    },
                                    model: {
                                      value: _vm.form.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "title", $$v)
                                      },
                                      expression: "form.title",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "Layer", rules: "required" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors, valid }) {
                                return [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.mapServiceChoices,
                                      label: "Layer",
                                      "error-messages": errors,
                                      success: valid,
                                      "item-text": "label",
                                      "item-value": "value",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function ({ item, on, attrs }) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "v-list-item",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.label) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.form.mapServiceId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "mapServiceId", $$v)
                                      },
                                      expression: "form.mapServiceId",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-end",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              type: "submit",
                              color: "primary",
                              id: "createFormBtn",
                            },
                          },
                          [_vm._v(" Create Form ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }