<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Forms" />
      <PageLayout>
        <v-data-table
          v-if="!showEditDialog"
          :headers="headers"
          :items="flattenedFormsArray"
          item-key="form_definition_id"
          disable-pagination
          show-group-by
          group-by="Layer"
          hide-default-footer
          :custom-filter="filter"
          :search="search"
          @click:row="openEditFormDialog"
          class="py-0 my-0 cursor-pointer"
          style="overflow-x: hidden !important"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12">
                <div
                  class="d-flex justify-space-between align-center gap px-2 pt-3"
                >
                  <v-text-field
                    v-model="search"
                    label="Search"
                    name="search"
                    append-icon="mdi-magnify"
                    hide-details="auto"
                  />
                  <div>
                    <v-btn
                      color="primary"
                      @click="
                        showNewFormDialog = true;
                        selectedFormDefinitionId = undefined;
                      "
                      id="add-form-button"
                    >
                      + Form
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="px-3 pt-0 pb-2 my-0 d-flex justify-start">
                <v-chip
                  class="d-flex"
                  @click="showActiveForms = !showActiveForms"
                >
                  <div
                    class="chip overflow-hidden text-truncate my-0 py-0 cursor-pointer"
                  >
                    {{ showActiveForms ? "Active" : "Archived" }}
                    <v-icon small>
                      {{ mdiSyncCircle }}
                    </v-icon>
                  </div>
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list v-if="item.is_active">
                <v-list-item
                  class="cursor-pointer"
                  @click="openEditFormDialog(item)"
                >
                  <v-icon class="mr-1">
                    {{ mdiPencil }}
                  </v-icon>
                  Edit Form
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click="updateIsActive(item.form_definition_id, false)"
                >
                  <v-icon class="mr-1">
                    {{ mdiArchiveArrowDown }}
                  </v-icon>
                  Archive Form
                </v-list-item>

                <v-list-item
                  class="cursor-pointer"
                  @click.stop="
                    showDeleteDialog = true;
                    itemToDelete = item;
                  "
                >
                  <v-icon class="mr-1">
                    {{ mdiDelete }}
                  </v-icon>
                  Delete Form
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item
                  class="cursor-pointer"
                  @click="updateIsActive(item.form_definition_id, true)"
                >
                  <v-icon class="mr-1">
                    {{ mdiArchiveArrowUp }}
                  </v-icon>
                  Make Active
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </PageLayout>

      <v-dialog
        v-model="showDeleteDialog"
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
      >
        <v-card class="pt-5">
          <v-card-text>
            Are you sure you want to delete this form? This cannot be undone.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                showDeleteDialog = false;
                itemToDelete = {};
              "
            >
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="deleteItem(itemToDelete)">
              Delete Form
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showNewFormDialog" max-width="400px">
        <NewFormForm
          :mapServicesResults="mapServicesResults"
          @new-form-close="showNewFormDialog = false"
          @create-new-form="
            showNewFormDialog = false;
            showEditDialog = true;
            formIsNewForm = true;
            setMenuState(false);
          "
        />
      </v-dialog>

      <v-dialog
        fullscreen
        v-model="showEditDialog"
        :retain-focus="false"
        persistent
        content-class="form-builder-edit-form"
      >
        <FormBuilderEditForm
          v-if="showEditDialog"
          @form-builder-edit-form-submitted="selectedFormDefinitionId = $event"
          @form-builder-edit-form-close="
            resetURL();
            formIsNewForm = false;
            showEditDialog = false;
            downloadMapServices();
            openMenu();
          "
          :selectedFormDefinitionId="selectedFormDefinitionId"
          :formIsNewForm="formIsNewForm"
        />
      </v-dialog>

      <v-dialog v-model="showDeleteErrorDialog" max-width="600px">
        <v-card class="pt-5">
          <v-card-text>
            This form cannot be deleted because it is being referenced in other
            places in the app. We recommend you archive the form instead.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showDeleteErrorDialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import axios from "axios";
import {
  mdiPencil,
  mdiDelete,
  mdiArchiveArrowUp,
  mdiArchiveArrowDown,
  mdiSyncCircle,
} from "@mdi/js";
import FormBuilderEditForm from "@/components/forms/FormBuilderEditForm.vue";
import NewFormForm from "@/components/forms/NewFormForm.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import usetifulMixin from "@/mixins/usetifulMixin";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;
const headers = [
  {
    text: "Form",
    align: "start",
    sortable: true,
    groupable: false,
    value: "title",
    width: 200,
  },
  {
    text: "Layer",
    align: "start",
    sortable: true,
    groupable: false,
    value: "serviceName",
  },
  { text: "Actions", value: "actions", sortable: false, groupable: false },
];

export default {
  name: "FormsPage",
  components: {
    TopBar,
    FormBuilderEditForm,
    NewFormForm,
    PageLayout,
  },
  computed: {
    flattenedFormsArray() {
      const formsArrayFlat = this.mapServicesResults
        .map(
          ({
            forms,
            service_name: serviceName,
            parent_map_service_id: parentMapServiceId,
          }) => {
            return forms.map((f) => ({
              ...f,
              Layer: parentMapServiceId
                ? `${this.getParentLayerName(
                    parentMapServiceId
                  )}: ${serviceName}`
                : serviceName,
              serviceName: parentMapServiceId
                ? `${this.getParentLayerName(
                    parentMapServiceId
                  )}: ${serviceName}`
                : serviceName,
            }));
          }
        )
        .flat();
      if (this.showActiveForms) {
        return formsArrayFlat.filter((l) => l.is_active);
      } else {
        return formsArrayFlat.filter((l) => !l.is_active);
      }
    },
  },
  mixins: [usetifulMixin],
  data() {
    return {
      mdiPencil,
      mdiDelete,
      mdiArchiveArrowUp,
      mdiArchiveArrowDown,
      mdiSyncCircle,
      headers,
      mapServicesResults: [],
      search: "",
      showDeleteDialog: false,
      itemToDelete: {},
      showEditDialog: false,
      showNewFormDialog: false,
      selectedFormDefinitionId: undefined,
      key: +new Date(),
      formIsNewForm: false,
      showActiveForms: true,
      showDeleteErrorDialog: false,
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    async openMenu() {
      this.setMenuState(false);
      await this.$nextTick();
      this.setMenuState(true);
    },
    async downloadMapServices() {
      let authResults;
      try {
        authResults = JSON.parse(localStorage.getItem("auth")) || {};
      } catch (error) {
        authResults = {};
      }

      const { user_group_id: userGroupId } = authResults;
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services`, {
        params: { userGroupId, isAdmin: true },
      });
      this.mapServicesResults = results;
    },
    filter(value, search, item) {
      const { title, serviceName } = item;
      return (
        (title || "")
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase()) ||
        (serviceName || "")
          .toLocaleLowerCase()
          .includes(this.search.toLocaleLowerCase())
      );
    },
    editItem({ form_definition_id: formDefinitionId }) {
      this.showEditDialog = true;
      this.selectedFormDefinitionId = formDefinitionId;
      this.setMenuState(false);
    },
    async deleteItem({ form_definition_id: formDefintionId }) {
      try {
        await axios.delete(`${APIURL}/form_definitions/${formDefintionId}`);
      } catch {
        this.showDeleteErrorDialog = true;
      } finally {
        this.itemToDelete = {};
        this.showDeleteDialog = false;
        await this.downloadMapServices();
      }
    },
    openEditFormDialog(item) {
      this.showEditDialog = true;
      this.selectedFormDefinitionId = item.form_definition_id;
      this.setMenuState(false);

      this.$router.push({
        path: "/forms",
        query: {
          [`form`]: item.form_definition_id,
          [`tab`]: "form-builder",
        },
      });
    },
    resetURL() {
      this.$router.push({
        path: "/forms",
      });
    },
    async updateIsActive(formDefinitionId, isActiveStatus = true) {
      await axios.put(
        `${APIURL}/form_definitions/${formDefinitionId}/is_active`,
        { is_active: isActiveStatus }
      );
      this.downloadMapServices();
    },
    getParentLayerName(mapServiceId) {
      const parentLayerName = this.mapServicesResults.find(
        (parent) => parent.map_service_id === mapServiceId
      )?.service_name;
      return parentLayerName ? parentLayerName : "Undefined";
    },
  },
  mounted() {
    if (!this.$route.query.form) {
      this.downloadMapServices();
    }
  },
  watch: {
    "$route.query.form": {
      immediate: true,
      deep: true,
      async handler() {
        const formDefId = this.$route.query.form;
        if (formDefId) {
          this.showEditDialog = true;
          this.selectedFormDefinitionId = formDefId;
          this.setMenuState(false);
        }
      },
    },
  },
};
</script>

<style>
.gap {
  gap: 10px;
}
</style>
